<template>
    <transition name="fade">
        <div class="details-wrapper page">
            <div class="secondary-header" v-if="ready">
                <div @click="() => { this.$router.push({path: '/vod'})}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    {{ this.type == 'movie' ? 'Movies' : 'Shows'}}
                </div>

                <div class="save" v-ripple @click="save">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="this.content.saved ? 'saved' : ''">
                        <path d="M5 21V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L13.0815 17.1953C12.4227 16.7717 11.5773 16.7717 10.9185 17.1953L5 21Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>

            <div class="details" v-if="ready" :class="type">
            <div class="background">
              <img :src="content.backdrop_path"/>

              <div class="poster" v-if="type === 'movie'">
                  <img :src="content.poster_path"/>
              </div>
              <div v-else>
                    <h5>
                        {{content.title || content.name}}
                        <b> ({{content.first_air_date.split('-')[0]}} - {{content.last_air_date.split('-')[0]}})   </b>
                    </h5>
              </div>
        </div>

        <div class="data-container" v-if="type == 'movie'">
            <div class="streams" v-if="content.streams.length > 0 || !extrasLoaded">
                <div :key="i" class="stream" @click="playMedia(s.url)" v-ripple v-for="(s, i) in content.streams">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    {{s.quality}}
                    <br/>
                    {{s.size}}
                </div>

                <div class="stream" @click="() => { loadMoreStreams() }" v-ripple v-if="!extrasLoaded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <path d="M15.5 15.5L19 19" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    <template v-if="loadingExtras"> loading </template>
                    <template v-else> load more </template>
                </div>
            </div>

            <div class="streams" v-else>
                <p v-if="ready">
                    No streams found
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M8.5 14C8.22386 14 8 13.7761 8 13.5C8 13.2239 8.22386 13 8.5 13C8.77614 13 9 13.2239 9 13.5C9 13.7761 8.77614 14 8.5 14Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.5 14C15.2239 14 15 13.7761 15 13.5C15 13.2239 15.2239 13 15.5 13C15.7761 13 16 13.2239 16 13.5C16 13.7761 15.7761 14 15.5 14Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 18H14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </p>

                <div class="stream animated" v-else> </div>
            </div>
        </div>

        <div class="data-container" v-if="type == 'movie'">
            <div class="data">
                  <h5>
                      {{content.title || content.name}}
                      <b> ({{content.release_date.split('-')[0]}}) </b>
                  </h5>

                  <div class="title-sup">
                      <span class="rating">
                          <div v-for="(r, i) in ratings(content.vote_average*10)" :key="i" :class="r">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                          </div>
                      </span>

                  </div>
                  <p class="desc" > {{content.overview}} </p>
                  <div class="genres" v-if="content">
                      <div class="genre" v-for="(g, i) in content.genres" :key="i">
                          {{g.name}}
                      </div>
                  </div>
              </div>
          </div>

          <div class="data-container" v-else>
              <div class="data ptop">
                  <div class="sselect">
                    <div v-for="(e,i) in new Array(content.number_of_seasons)" :key="i" @click="changeSeason(i + 1)" v-ripple>
                        <div class="active" v-if="activeSeason === i + 1"> Season {{ i + 1 }}</div>
                        <div v-else> {{ i + 1 }}</div>
                    </div>
                </div>

                    <div class="eps">
                        <div class="cover-op" v-for="(e, i) in activeEpisodes" :key="i"  v-ripple @click="changeEpisode(i+1)">
                            <div  v-if="e.still_path  || (activeEpisodes[i].overview && activeEpisodes[i].name)">
                                <img :src="'https://image.tmdb.org/t/p/w500/' + e.still_path"/>
                                <div class="content">
                                    <small>
                                        <div class="red">
                                            season {{activeSeason}}
                                        </div>
                                        <div>
                                            episode {{i + 1}}
                                        </div>
                                    </small>

                                    <div class="summary">
                                        <span> <b>{{activeEpisodes[i].name}}</b> </span>
                                        <p> {{clampText(activeEpisodes[i].overview, 150)}} </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
              </div>
           </div>



        <div class="data-container no-flex" v-if="type == 'tv' && !epsloading">
            <span> SEASON {{activeSeason}} EPISODE {{activeEpisode}} </span>
            <div class="streams short" v-if="content.streams.length > 0 || !extrasLoaded">
                <div :key="i" class="stream" @click="playMedia(s.url)" v-ripple v-for="(s, i) in content.streams">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    {{s.quality}}
                    <br/>
                    {{s.size}}
                </div>
                <div class="stream" @click="() => { loadMoreStreams() }" v-ripple v-if="!extrasLoaded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <path d="M15.5 15.5L19 19" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    <template v-if="loadingExtras"> loading </template>
                    <template v-else> load more </template>
                </div>

            </div>

            <div class="streams short" v-else>
                <div class="stream animated" v-if="loadingExtras"> </div>

                <p v-else>
                    No streams found
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M8.5 14C8.22386 14 8 13.7761 8 13.5C8 13.2239 8.22386 13 8.5 13C8.77614 13 9 13.2239 9 13.5C9 13.7761 8.77614 14 8.5 14Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.5 14C15.2239 14 15 13.7761 15 13.5C15 13.2239 15.2239 13 15.5 13C15.7761 13 16 13.2239 16 13.5C16 13.7761 15.7761 14 15.5 14Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 18H14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </p>
            </div>
        </div>


          <div class="data" v-if="content.credits && content.credits.cast">
              <h2> Actors </h2>
              <div class="content-row">
                  <MediaGroup  v-for="(media, i) in content.credits.cast.filter((x,i) => { if(i < 11) {return x} })" :key="i" :media="media"/>
              </div>
          </div>

          <div class="data" v-if="type=='movie' && content.credits && content.credits.crew">
              <h2> Crew </h2>
              <div class="content-row">
                  <MediaGroup  v-for="(media, i) in content.credits.crew.filter((x,i) => { if(i > 10) {return x} })" :key="i" :media="media"/>
              </div>
          </div>

          <div class="data" v-if="content.production_companies">
              <h2 v-if="type == 'movie'"> Production </h2>
              <h2 v-else> Network </h2>

              <div class="content-row">
                  <MediaGroup  v-for="(media, i) in content.credits.production" :key="i" :media="media"/>
              </div>
          </div>

          <div class="data">
              <h2> More Like {{content.title || content.name}} </h2>
              <div v-if="ready" class="content-row">
                  <Movie v-ripple v-for="(content, i) in content.reccomended" :key="i" :media="content" :list="true" :display="true" />
              </div>
          </div>

      </div>
  </div>
  </transition>
</template>

<script>
const MediaGroup = () => import('@/components/MediaGroup.vue')
const Movie = () => import('@/components/Movie.vue')

export default {
  name: 'Details',

  components: {
      Movie,
      MediaGroup
  },

  watch: {
      $route() {
        this.loadDetails()
      }
  },

  data: function(){
    return {
        ready: false,
        epsloading: false,

        loadingExtras: false,
        extrasLoaded: false,

        content: null,

        activeEpisodes: [],
        activeEpisode: 1,
        activeSeason: 1,

    }
  },

  methods: {
    clampText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        } else {
            return text.substring(0, maxLength - 3) + '...';
        }
    },


    loadDetails: async function(){
        this.ready = false;

        this.$nextTick(() => { window.scrollTo({top: 0, behavior: 'smooth'}); })

        const response  = await fetch(process.env.VUE_APP_SERVER + `/details?id=${this.$route.params.id}&type=${this.type}`)
        this.content =  await response.json()

        if(this.type == 'tv'){
            this.activeEpisodes = this.content.season.episodes
            if(this.content.history && this.content.history.episode > 0){
                this.changeSeason(this.content.history.season)
                this.changeEpisode(this.content.history.episode)
            }
        }


        this.ready = true
    },

    async getStandards(){

    },

    async loadMoreStreams(mode='scrape'){
      if(this.loadingExtras){return}
      this.loadingExtras = true
      const response  = await fetch(`${process.env.VUE_APP_SERVER}/streams?id=${this.content.external_ids.imdb_id}&mode=${mode}&title=${this.content.title || this.content.name}&type=${this.type}&e=${this.activeEpisode}&s=${this.activeSeason}`)
      const data = await response.json()
      this.content.streams =  [...this.content.streams, ...data.streams]
      this.loadingExtras = false
      this.epsloading = false
      if(mode === 'scrape'){
        this.extrasLoaded = true
      }
    },

    ratings(rating){
        let stars = []
        let wholeRating = rating/20
        stars = [...Array(Math.floor(wholeRating)).keys()].map(() => {  return 'whole' })
        if(parseInt(wholeRating) !== wholeRating){
            stars.push('half')
        }
        return stars
    },

    async changeEpisode(ep){
        this.activeEpisode = ep
        window.episode = ep
        this.extrasLoaded = false
        this.epsloading = true
        this.content.streams = []
        await this.loadMoreStreams('pt')

        this.$nextTick(() => {
            document.querySelectorAll('.cover-op')[ep - 1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center", })
        })

    },

    async changeSeason(season){
        const response  = await fetch(process.env.VUE_APP_SERVER + `/season?id=${this.$route.params.id}&type=${this.type}&season=${season}`)
        const data =  await response.json()
        this.activeEpisodes = data.episodes
        this.activeSeason = season
        this.content.streams = []
        this.loadMoreStreams('pt')
    },

    async save(){
        let data = {
            type: 'od',
            id: this.$route.params.id,
            ref: window.location.path,
            name: (this.content.title || this.content.name),
            imgs: [this.content.poster_path, this.content.backdrop_path],
            contentType: this.type,
        }

        const req = await fetch(process.env.VUE_APP_SERVER + `/save`, {method: 'POST', body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
        const res = await req.json()
        this.content.saved = res.saved
    },

    playMedia: function(mag){
        if(!window.did){
            this.$root.$emit('collectDid', {cb: this.playMedia.bind(this), params: mag})
            return
        }

        let data = {
            type: 'od',
            id: this.$route.params.id,
            did: window.did,
            ref: window.location.href,
            url: mag,
            name: (this.content.title || this.content.name),
            logos: [this.content.poster_path, this.content.backdrop_path],

            details: {
                contentType: this.type,
                actors: this.content.credits.cast.slice(0,5),
                genres: this.content.genres,
            }
        }

        if(this.type == 'movie'){
            data.details.date = this.content.release_date.split('-')[0]
            data.details.runtime = this.content.runtime || 75
        }else{
            data.details.date = this.content.first_air_date.split('-')[0]
            data.details.episode = this.activeEpisode
            data.details.season = this.activeSeason
            data.details.runtime = this.content.episode_run_time[0] || 30
            data.details.img = 'https://image.tmdb.org/t/p/w500/' +  this.activeEpisodes[this.activeEpisode - 1].still_path
        }

        fetch(process.env.VUE_APP_SERVER + `/start`, {method: 'POST', body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
    },
  },


  computed: {

    type: function(){
        if(window.location.href.includes('movie')){
            return 'movie'
        }else{
            return 'tv'
        }
    }

  },

    mounted: async function(){
        await this.loadDetails()
    }
}

</script>

<style scoped lang="scss">
.details{
    .content{
        position: relative;
        width: 100vw;
    }


    .data{
        padding-bottom: 10px;

        h2{
            padding-left: 20px;
        }

        h4{ margin-bottom: 0; }
    }
    .title-sup{
        width: 100%;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        > div {
            display: flex;
            flex-direction: column;
        }
        button{
            padding: 10px 20px;
            font-size: 9pt;
            margin-bottom: 10px;
            font-weight: bold;
            border-radius: 4px;
            outline: none;
            color: white;
            border: none;
            background-color: rgba(white, 0.1);
        }
    }

    .rating{
        color: white;
        display: flex;
        opacity: 0.5;

        > div{
            position: relative;
            width: 20px;
            height: 20px;
            overflow: hidden;
            margin-right: 4px;
            &.half{
                width: 10px;
            }
        }
        svg{
            position: absolute;
            top: 0;
            left: 0;
            fill: white;
            width: 20px;
            height: 20px;
        }
    }

    .eps{
        width: 100vw;
        overflow: scroll;
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .cover-op{
        position: relative;
        padding-left: 20px;
        &:last-of-type{
            padding-right: 20px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .summary{
            width: calc(90% - 10px);
            padding: 10px;
            backdrop-filter: blur(20px);
            background-color: rgba(255,255,255,0.1);
            margin-bottom: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 10px;

        }
        >div{
            width: 80vw;
            max-width: 375px;
            position: relative;
            min-height: 300px;
            overflow: hidden;
            border-radius: 8px;
        }

        .content{
            z-index: 8;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            background-color: rgba(0,0,0,0.2);

            .badge{
                position: absolute;
                color: white;
                bottom: 20px;
                right: 20px;
                display: flex;
                font-size: 9pt;
                svg{
                    color: white;
                    width: 13px;
                    padding-right: 5px;
                }
            }
            h4, p{
                margin: 0;
                padding: 0;
            }

            p{
                font-size: 9px;
                padding: 20px 0;
                width: 100%;
            }

            h4{
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 14pt;
            }

            small{
                display: block;
                margin-top: 20px;
                text-transform: uppercase;
                font-size: 7pt;
                font-weight: bold;
                display: flex;
                text-shadow: 0 0px 13px rgba(0, 0, 0, 0.85);
                .red{
                    color: #e74c3c;
                    padding-right: 10px;
                }
            } .ep-data{
                color: white;
                margin: 20px;
                width: 85%;

            }
            span{
                right: 20px;
                font-size: 7pt;
                padding-bottom: 20px;
                top: 20px;
                color: white;
                font-weight: bold;

            }
        }
    }

    .data-container{
        display: flex;
        color: white;
        width: 100vw;

        p{
            line-height: 1.75;
            width: 100%;
            font-size: 10pt;
            text-align: justify;
        }
        .genres{
            padding: 10px 0;
            display: flex;
            flex-wrap: wrap;
            .genre{
                margin: 8px 4px;
                padding: 10px 15px;
                border-radius: 12px;
                transition: all 300ms ease;
                font-size: 9pt;
                border: 1px solid transparent;
                background-color: rgba(white, 0.1);
                border-radius: 90px;
            }
        }
        .data{
            width: calc(100% - 40px);
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &.ptop{ padding: 0; }

            span{
                margin: 0;
                padding: 0;
                font-size: 11pt;
                padding-bottom: 10px
            }
        }
    }

    h5{
        font-size: 16pt;
        text-transform: uppercase;
        margin: 0;
        text-overflow: wrap;
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        b{
            display: block;
            font-size: 9pt;
            padding-top: 5px;
            padding-left: 20px;
            opacity: 0.5;
        }
    }

    .background{
        width: 100vw;
        height: 350px;
        margin-bottom: 200px;
        position: relative;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        .content{
            height: 30px;
            padding-left: 20px;
            top: calc(35vh - 60px);
        }

        > img{
            filter: blur(25px);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
            object-fit: cover;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;

        }
        .poster{
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 275px;
            height: auto;
            z-index: 9;
            img{
                width: 100%;
                border-radius: 15px;
                overflow: hidden;
                height: auto;
                box-shadow: -3px 3px 12px 2px rgba(0, 0, 0, 0.5);
            }
        }
    }


    &.tv{
        .background{
            height: 275px;
            margin-bottom: 0px;
            overflow: hidden;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            &::before{
                pointer-events: none;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: linear-gradient(0deg, rgba(#141414,1) 0%, rgba(#141414,0.84) 30%, rgba(#141414, 0) 100%);
            }
        }
        h5{
            position: relative;
            font-size: 26pt;
            padding: 85px 20px;
            width: 70%;
            text-align: left;
            z-index: 2;
            flex-wrap: wrap;
            justify-content: flex-start;
            b{
                min-width: 100%;
                flex-grow: 1;
                padding-left: 0;
            }
        }

        .background > img{
            filter: none;
            opacity: 75;
            object-fit: cover;
            top: 0;
            min-width: 100vw;
            min-height: 200px;
            width: auto;
            height: auto;
        }
    }

}

.data-container.no-flex{
    display: block;
    padding-bottom: 40px;
    span{
        font-weight: bold;
        padding-left: 20px;
    }
}

.data-container .streams, .data-container .stream{
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    overflow-x: scroll;
    align-items: center;
    height: 75px;
    padding-top: 20px;
    &.streams{
        &.short{
            padding-top: 20px;
        }
    }
    > p{
        border: 1px solid white;
        width: calc(100vw - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px 0;
        border-radius: 3px;
        svg{
            margin: 0;
            padding: 0;
            padding: 5px;
        }
    }
    small{
        padding-top: 5px;
    }
    svg{
        height: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .stream{
        font-size: 9px;
        border: 1px solid white;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #111;
        margin-right: 10px;
        padding: 5px 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        min-width: 100px;
        overflow: hidden;
        text-align: center;
    }
}


.sselect{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 20px;
    margin-top:  0px;
    width: 100%;
    >div{
        margin-right: 10px;
        margin-bottom: 10px;

        >div{
            padding: 1px 5px;
            border-radius: 5px;
        }

        > div:not(.active){
            border: 1px solid white;
        }
    }
    .active{
        background-color: red;
        min-width: 85px;
        text-align: center;
        padding: 2px 5px;
    }
}

</style>
